const state = {
  queryCache: {}
}

const mutations = {
  SAVE_QUERY(state, { path, query }) {
    state.queryCache[path] = query
  },
  CLEAR_QUERY(state, path) {
    delete state.queryCache[path]
  }
}

const actions = {
  saveQuery({ commit }, { path, query }) {
    commit('SAVE_QUERY', { path, query })
  },
  clearQuery({ commit }, path) {
    commit('CLEAR_QUERY', path)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
