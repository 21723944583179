import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import tabQuery from './modules/tabQuery'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    webData: {},
    caseCenterData: []
  },
  mutations: {
    WEB_DATA: (state, info) => {
      if (!info) {
        return
      }
      state.webData = info
    },
    setCaseCenterData: (state, data) => {
      if (!data) {
        return
      }
      state.caseCenterData = data
    }
  },
  actions: {},
  modules: {
    user,
    tabQuery
  }
})
